import React, { useState } from "react";
import ActionCards from "../components/ActionCards";
import Button from "../components/Button";
import Layout from "../components/Layout";
import List from "../components/List";
import Modal from "../components/Modal";
import Stats from "../components/Stats";
import Seo from "../components/Seo";

// markup
const BuildPage = () => {
  const [totalCost, setTotalCost] = useState(
    process.env.BASE_WEBSITE_COST || 800
  );
  const [totalLowerTime, setTotalLowerTime] = useState(
    process.env.BASE_LOWER_TIME || 8
  );
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Seo pathname="/build" />
      <Layout title="Build">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <title>Home Page</title>
          <div className="max-w-3xl mx-auto mt-10">
            <div className="pb-5 mb-10 border-b border-gray-200">
              <h3 className="text-6xl font-bold text-gray-900 inline-block">
                Let's build your site
              </h3>
            </div>
            <List />
            <ActionCards
              setTotalCost={setTotalCost}
              setTotalLowerTime={setTotalLowerTime}
            />
            <Stats totalCost={totalCost} totalLowerTime={totalLowerTime} />
            <Button onClick={() => setShowModal(true)} />
            <Modal
              open={showModal}
              setOpen={setShowModal}
              totalCost={totalCost}
              totalLowerTime={totalLowerTime}
            />
          </div>
        </main>
      </Layout>
    </>
  );
};

export default BuildPage;
