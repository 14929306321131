import * as React from "react";

export default function Stats({ totalCost, totalLowerTime }) {
  const stats = [
    { name: "Minimum Cost", stat: `$${totalCost}` },
    { name: "Minimum Time", stat: `${totalLowerTime} days` },
  ];
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
        {stats.map((item) => (
          <div
            key={item.name}
            className="px-4 mt-3 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
          >
            <dt className="text-sm font-medium text-gray-500 truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-yellow-900">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
