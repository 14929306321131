/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Toggle from "./Toggle";

const extras = [
  {
    name: "Bookings",
    title:
      "Add a scheduler to your site and be updated about client appointments in real time.",
    cost: 70,
    minTime: 3,
    imageUrl:
      "https://images.unsplash.com/photo-1506784983877-45594efa4cbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2368&q=80",
  },
  {
    name: "Content Writing",
    title: `Don't have time to write about your business? We'll take care of it.`,
    minTime: 3,
    cost: 50,
    imageUrl:
      "https://images.unsplash.com/photo-1488190211105-8b0e65b80b4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80",
  },
  {
    name: "Customisable Content",
    title: `Want to be able to change the content yourself in the future? We can do that for you.`,
    minTime: 2,
    cost: 70,
    imageUrl:
      "https://images.unsplash.com/photo-1581116536919-e906d33a4157?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
  },
];

const handleDifference = (prevState, acc, isAdding, type) => {
  if (isAdding === null)
    return type === "cost"
      ? parseFloat(process.env.BASE_WEBSITE_COST)
      : parseFloat(process.env.BASE_LOWER_TIME);
  return isAdding ? (prevState += acc) : (prevState -= acc);
};

export default function ActionCards({ setTotalCost, setTotalLowerTime }) {
  return (
    <ul role="list" className="grid grid-cols-1 gap-6 lg:grid-cols-3">
      {extras.map((extra) => (
        <li
          key={extra.name}
          className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
        >
          <div className="flex-1 flex flex-col">
            <img
              className="w-full h-64 object-cover flex-shrink-0 mx-auto"
              src={extra.imageUrl}
              alt=""
            />
            <div className="p-8">
              <h3 className="text-gray-900 text-sm font-medium">
                {extra.name}
              </h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Title</dt>
                <dd className="text-gray-500 text-sm">{extra.title}</dd>
                <dt className="sr-only">Cost</dt>
                <dd className="mt-3">
                  <span className="px-2 py-1 text-yellow-800 text-xs font-medium bg-yellow-100 rounded-full">
                    {`$${extra.cost} / hour`}
                  </span>
                </dd>
              </dl>
            </div>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="-ml-px w-0 flex-1 flex">
                <button
                  onClick={() => {}}
                  className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                >
                  <Toggle
                    onChange={(enabled) => {
                      setTotalCost((prevState) =>
                        handleDifference(
                          prevState,
                          extra.cost * extra.minTime * 3,
                          enabled,
                          "cost"
                        )
                      );
                      setTotalLowerTime((prevState) =>
                        handleDifference(
                          prevState,
                          extra.minTime,
                          enabled,
                          "lowerTime"
                        )
                      );
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
