import * as React from 'react'

export default function Button({ onClick }) {
    return (
        <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={onClick}
            className="ml-3 w-full mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
          >
            Build
          </button>
        </div>
      </div>
    )
}